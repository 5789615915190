// Here you can add other styles
body {
  background-color: #fafafa;
}

.card.bg-primary {
  border-color: #31a695;
}

select.form-control,
input.form-control {
  max-width: 220px;
}

.dropdown-item {
  border-bottom: 0px;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 200px;
    clear: left;
    text-align: left;
    text-emphasis: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 220px;
  }
}

.dl-horizontal dd:before,
.dl-horizontal dd:after {
  display: table;
  content: ' ';
}

.dl-horizontal dd:after {
  clear: both;
}

.thirdPartyIdInput {
  margin-bottom: 4px;
}

.dropdown-searchbox:focus {
  box-shadow: none !important;
}
